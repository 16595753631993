import styled from 'styled-components';

import {colors, dimensions} from '@stryd/react-ui';

import {dimensions as customDimensions} from 'src/styles/dimensions';

const {
  defaultPageVerticalPadding,
  defaultPageSidePadding,
  defaultPageVerticalPaddingMobile,
  defaultPageSidePaddingMobile,
} = customDimensions;

export const pageBackground = colors.themes.dark.backgroundLayers[1];

export const PageContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
  padding: ${defaultPageVerticalPadding} ${defaultPageSidePadding};

  background-color: ${pageBackground};
  color: ${colors.themes.dark.text};

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${colors.themes.dark.headerText};
  }

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    padding: ${defaultPageVerticalPaddingMobile} ${defaultPageSidePaddingMobile};
    align-items: center;
  }
`;

export const PageContentContainer = styled.div`
  width: 325px;
  max-width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 1.5rem;
  }
`;
