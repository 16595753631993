import {useState} from 'react';

import {getDaysInMonth} from 'date-fns';
import {range} from 'ramda';
import {useForm, Controller} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {CheckBox, colors} from '@stryd/react-ui';
import {BorderOnlyActionButton, dimensions} from '@stryd/react-ui';

import {NumberInput} from 'src/components/NumberInput';
import {Select} from 'src/components/Select';
import {AuthPageHeading} from 'src/components/checkout-auth-page/AuthPageHeading';

import {StepContainer} from './styles';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: ${dimensions.twicePadding};
  }
`;

const InputsContainer = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const SubmitButton = styled(BorderOnlyActionButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 9999px;
`;

const DateOfBirth = styled.div`
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: -8px;
`;

const RowContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;

const PolicyLine = styled.div`
  display: flex;
  align-items: center;
  background: #3b3e41;
  border-radius: 4px;
  height: 42px;
  padding: 0 10px 0 15px;
  font-size: 17px;
  color: #fff;

  > *:last-child {
    margin-left: auto;
  }
`;

const DocumentLinkAnchor = styled.a`
  color: ${colors.themes.dark.textHighlight};
  margin-left: 4px;

  &:hover {
    text-decoration: underline;
  }
`;

const genderOptions = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Prefer to Self-Describe',
    value: 'other',
  },
];
const yearOptions = range(1900, new Date().getFullYear() + 1).map((year) => ({
  label: String(year),
  value: String(year),
}));
const monthOptions = [
  {
    label: 'Jan',
    value: '1',
  },
  {
    label: 'Feb',
    value: '2',
  },
  {
    label: 'Mar',
    value: '3',
  },
  {
    label: 'Apr',
    value: '4',
  },
  {
    label: 'May',
    value: '5',
  },
  {
    label: 'Jun',
    value: '6',
  },
  {
    label: 'Jul',
    value: '7',
  },
  {
    label: 'Aug',
    value: '8',
  },
  {
    label: 'Sep',
    value: '9',
  },
  {
    label: 'Oct',
    value: '10',
  },
  {
    label: 'Nov',
    value: '11',
  },
  {
    label: 'Dec',
    value: '12',
  },
];

// eslint-disable-next-line
const renderLinkChunk = (href: string) => (chunk: string) => {
  return (
    <DocumentLinkAnchor href={href} rel="noopener noreferrer" target="_blank">
      {chunk}
    </DocumentLinkAnchor>
  );
};

export interface StepFiveInputs {
  gender: string;
  height: string;
  weight: string;
  day: string;
  month: string;
  year: string;
  distanceUnit: string;
}

export interface StepFiveProps {
  onSubmit: (data: StepFiveInputs) => void;
}

export const StepFive: React.FC<StepFiveProps & StepFiveInputs> = (props) => {
  const {gender, height, weight, day, month, year, distanceUnit, onSubmit} =
    props;

  const intl = useIntl();
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const {
    handleSubmit,
    control,
    formState: {errors},
    watch,
  } = useForm<StepFiveInputs>({
    defaultValues: {
      gender,
      height,
      weight,
      day,
      month,
      year,
    },
  });

  const hasError = Object.keys(errors).length > 0;

  const dayOptions = range(
    1,
    getDaysInMonth(new Date(+watch('year'), +watch('month') - 1)) + 1
  ).map((day) => ({label: String(day), value: String(day)}));

  const disabled =
    hasError ||
    !privacyChecked ||
    !watch('day') ||
    !watch('month') ||
    !watch('year') ||
    !watch('gender') ||
    !watch('height') ||
    watch('height') === '0' ||
    !watch('weight') ||
    watch('weight') === '0';

  const handleSubmitInner = handleSubmit((data) => {
    if (!disabled) onSubmit(data);
  });

  const heightUnit = distanceUnit === 'meters' ? 'cm' : 'inches';
  const weightUnit = distanceUnit === 'meters' ? 'kg' : 'lbs';

  return (
    <StepContainer>
      <AuthPageHeading
        heading={intl.formatMessage({
          id: 'SignUpPageTemplate.steps.others.header',
          defaultMessage: `Almost Done!`,
        })}
        subHeading={intl.formatMessage({
          id: 'SignUpPageTemplate.steps.others.message',
          defaultMessage: `This helps us give you relevant comparison information and suggestions.`,
        })}
      />
      <Form onSubmit={handleSubmitInner}>
        <InputsContainer>
          <Controller
            name="gender"
            control={control}
            render={({field: {name, onChange, value}}) => (
              <Select
                placeholder={intl.formatMessage({
                  id: 'SignUpPageTemplate.steps.others.distanceUnit.label',
                  defaultMessage: `Gender`,
                })}
                name={name}
                onChange={onChange}
                value={value}
                options={genderOptions}
              />
            )}
          />
          <RowContainer>
            <Controller
              name="height"
              control={control}
              render={({field: {name, onChange, value}}) => (
                <NumberInput
                  label={
                    intl.formatMessage({
                      id: 'SignUpPageTemplate.steps.others.height.label',
                      defaultMessage: `Height`,
                    }) + ` (${heightUnit})`
                  }
                  name={name}
                  onChange={onChange}
                  value={value}
                  integerOnly
                  aria-required={true}
                />
              )}
            />
            <Controller
              name="weight"
              control={control}
              render={({field: {name, onChange, value}}) => (
                <NumberInput
                  label={
                    intl.formatMessage({
                      id: 'SignUpPageTemplate.steps.others.weight.label',
                      defaultMessage: `Weight`,
                    }) + ` (${weightUnit})`
                  }
                  name={name}
                  onChange={onChange}
                  value={value}
                  integerOnly
                  aria-required={true}
                />
              )}
            />
          </RowContainer>
          <DateOfBirth>
            <FormattedMessage
              id="SignUpPageTemplate.steps.others.dateOfBirth.label"
              defaultMessage={`Date of Birth`}
            />
          </DateOfBirth>
          <RowContainer>
            <Controller
              name="month"
              control={control}
              render={({field: {name, onChange, value}}) => (
                <Select
                  placeholder={intl.formatMessage({
                    id: 'SignUpPageTemplate.steps.others.month.label',
                    defaultMessage: `Month`,
                  })}
                  name={name}
                  onChange={onChange}
                  value={value}
                  options={monthOptions}
                />
              )}
            />
            <Controller
              name="day"
              control={control}
              render={({field: {name, onChange, value}}) => (
                <Select
                  placeholder={intl.formatMessage({
                    id: 'SignUpPageTemplate.steps.others.day.label',
                    defaultMessage: `Day`,
                  })}
                  name={name}
                  onChange={onChange}
                  value={value}
                  options={dayOptions}
                />
              )}
            />
            <Controller
              name="year"
              control={control}
              render={({field: {name, onChange, value}}) => (
                <Select
                  placeholder={intl.formatMessage({
                    id: 'SignUpPageTemplate.steps.others.year.label',
                    defaultMessage: `Year`,
                  })}
                  name={name}
                  onChange={onChange}
                  value={value}
                  options={yearOptions}
                />
              )}
            />
          </RowContainer>
          <PolicyLine>
            <FormattedMessage
              id="SignUpPageTemplate.steps.others.agreeToPrivacy.label"
              defaultMessage={`I Agree to the {privacyPolicy}`}
              values={{
                privacyPolicy: renderLinkChunk('https://www.stryd.com/privacy')(
                  intl.formatMessage({
                    id: 'SignUpPageTemplate.steps.others.privacy.label',
                    defaultMessage: 'Privacy Policy',
                  })
                ),
              }}
            />
            <CheckBox
              id="signup-privacy-check"
              label=""
              checked={privacyChecked}
              onChange={(e) => setPrivacyChecked(e.target.checked)}
            />
          </PolicyLine>
        </InputsContainer>
        <SubmitButton disabled={disabled} type="submit">
          <FormattedMessage id="actions.submit" defaultMessage="Submit" />
        </SubmitButton>
      </Form>
    </StepContainer>
  );
};
