import React, {useState} from 'react';

import {useForm, Controller} from 'react-hook-form';
import {useIntl, FormattedMessage, MessageDescriptor} from 'react-intl';
import styled from 'styled-components';

import {create} from '@stryd/api-client';
import {BorderOnlyActionButton, Spinner, dimensions} from '@stryd/react-ui';
import {isValidEmail} from '@stryd/util-lib';

import {NoSSR} from 'src/components/NoSSR';
import {PasswordInput} from 'src/components/PasswordInput';
import {TextInput} from 'src/components/TextInput';
import {AuthPageHeading} from 'src/components/checkout-auth-page/AuthPageHeading';

import {StepContainer} from './styles';

const strydApi = create({baseURL: process.env.NEXT_PUBLIC_STRYD_API_BASEURL});

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: ${dimensions.twicePadding};
  }
`;

const InputsContainer = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const SubmitButton = styled(BorderOnlyActionButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 9999px;

  > * + * {
    margin-left: ${dimensions.defaultPadding};
  }
`;

const validateUsername = async (
  username: string
): Promise<{err: MessageDescriptor | null}> => {
  if (!username) {
    return {
      err: {
        id: 'errors.required',
        defaultMessage: 'This is required.',
      },
    };
  }

  const userCount = await strydApi.auth
    .getUserMetaData({username})
    .then((res) => res.data?.count);

  if (userCount !== 0) {
    return {
      err: {
        id: 'errors.usernameExists',
        defaultMessage: 'This username has been taken. Please try another one.',
      },
    };
  }

  return {err: null};
};

const validateEmail = async (
  email: string
): Promise<{err: MessageDescriptor | null}> => {
  if (!email) {
    return {
      err: {
        id: 'errors.required',
        defaultMessage: 'This is required.',
      },
    };
  }

  if (!isValidEmail(email)) {
    return {
      err: {
        id: 'errors.invalidEmail',
        defaultMessage: "It looks like this isn't a valid email address.",
      },
    };
  }

  const emailCount = await strydApi.auth
    .getUserMetaData({email})
    .then((res) => res.data?.count);

  if (emailCount !== 0) {
    return {
      err: {
        id: 'errors.accountExists',
        defaultMessage:
          'You already have an account. Please sign in with your email and password.',
      },
    };
  }

  return {err: null};
};

export interface StepOneInputs {
  username: string;
  email: string;
  password: string;
  isCreatingFromSocial: boolean;
}

export interface StepOneProps {
  onSubmit: (data: StepOneInputs) => void;
}

export const StepOne: React.FC<StepOneProps & StepOneInputs> = (props) => {
  const {username, email, password, isCreatingFromSocial, onSubmit} = props;

  const intl = useIntl();

  const [validating, setValidating] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
    clearErrors,
  } = useForm<StepOneInputs>({
    defaultValues: {
      username,
      email,
      password,
    },
    // only validate on submit
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: async (values) => {
      setValidating(true);
      const [{err: usernameErr}, {err: emailErr}] = await Promise.all([
        validateUsername(values.username),
        validateEmail(values.email),
      ]);
      setValidating(false);

      if (usernameErr || emailErr) {
        return {
          values,
          errors: {
            ...(usernameErr
              ? {
                  username: {
                    type: 'validate',
                    message: intl.formatMessage(usernameErr),
                  },
                }
              : null),
            ...(emailErr
              ? {
                  email: {
                    type: 'validate',
                    message: intl.formatMessage(emailErr),
                  },
                }
              : null),
          },
        };
      }

      if (!values.password && !isCreatingFromSocial) {
        return {
          values,
          errors: {
            password: {
              type: 'required',
              message: intl.formatMessage({
                id: 'errors.required',
                defaultMessage: 'This is required.',
              }),
            },
          },
        };
      }

      return {values, errors: {}};
    },
  });

  const hasError = Object.keys(errors).length > 0;
  const disabled =
    validating ||
    hasError ||
    !watch('username') ||
    !watch('email') ||
    (!isCreatingFromSocial && !watch('password'));

  return (
    <StepContainer>
      <AuthPageHeading
        heading={
          <FormattedMessage
            id="SignUpPageTemplate.signUpHeader"
            defaultMessage="Create your account"
          />
        }
        subHeading={
          <FormattedMessage
            id="SignUpPageTemplate.signUpMessage"
            defaultMessage="Choose a username, email and password that you would like to use to login to your account."
          />
        }
      />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsContainer>
          <Controller
            name="username"
            control={control}
            render={({field: {name, onChange, value}}) => (
              <TextInput
                label={intl.formatMessage({
                  id: 'auth.usernameLabel',
                  defaultMessage: 'Username',
                })}
                name={name}
                onChange={(e) => {
                  clearErrors('username');
                  onChange(e);
                }}
                value={value}
                errorMessage={errors?.username?.message}
                aria-required={true}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({field: {name, onChange, value}}) => (
              <TextInput
                label={intl.formatMessage({
                  id: 'auth.emailLabel',
                  defaultMessage: 'Email Address',
                })}
                name={name}
                onChange={(e) => {
                  clearErrors('email');
                  onChange(e);
                }}
                value={value}
                errorMessage={errors?.email?.message}
                aria-required={true}
              />
            )}
          />
          <NoSSR>
            {!isCreatingFromSocial && (
              <Controller
                name="password"
                control={control}
                render={({field: {name, onBlur, onChange, value}}) => (
                  <PasswordInput
                    label={intl.formatMessage({
                      id: 'auth.passwordLabel',
                      defaultMessage: 'Password',
                    })}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    errorMessage={errors?.password?.message}
                    aria-required={true}
                  />
                )}
              />
            )}
          </NoSSR>
        </InputsContainer>
        <SubmitButton disabled={disabled} type="submit">
          <span>
            <FormattedMessage id="actions.next" defaultMessage="Next" />
          </span>
          {validating && <Spinner size={'1rem'} />}
        </SubmitButton>
      </Form>
    </StepContainer>
  );
};
