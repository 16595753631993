import styled from 'styled-components';

export interface AuthPageHeadingProps {
  heading: React.ReactNode;
  subHeading: React.ReactNode;
}

const MainHeading = styled.h1`
  font-size: 2rem;
`;

export const AuthPageHeading: React.FC<AuthPageHeadingProps> = ({
  heading,
  subHeading,
}) => {
  return (
    <div>
      <MainHeading>{heading}</MainHeading>
      <p>{subHeading}</p>
    </div>
  );
};
