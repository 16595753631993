/* eslint-disable jsx-a11y/label-has-associated-control  */
import {FC} from 'react';

import cn from 'classnames';
import {useSelect} from 'downshift';

import styles from './Select.module.css';

type SelectValue = string;

export interface SelectOption {
  label: string;
  value: string;
}

export interface SelectProps {
  label?: string;
  placeholder?: string;
  name?: string;
  value?: SelectValue | null;
  options: SelectOption[];
  onChange: (newValue?: SelectValue | null) => void;
}

export const Select: FC<SelectProps> = (props) => {
  const {label, placeholder, value, options, onChange} = props;
  const {
    selectedItem,
    isOpen,
    getLabelProps,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useSelect({
    items: options,
    defaultSelectedItem: value
      ? options.find((option) => value === option.value)
      : undefined,
    onSelectedItemChange: ({selectedItem}) => {
      onChange(selectedItem?.value);
    },
  });

  return (
    <div className={styles.wrapper}>
      {label ? <label {...getLabelProps()}>{label}</label> : null}

      <div>
        <button
          type="button"
          {...getToggleButtonProps()}
          className={cn([styles.toggle, isOpen && styles.toggleOpen])}
        >
          {selectedItem?.label}
          <i className={cn([styles.arrow, isOpen && styles.arrowOpen])} />
        </button>
        {placeholder ? (
          <span
            className={cn([
              styles.placeholder,
              !!selectedItem && styles.placeholderAway,
            ])}
          >
            {placeholder}
          </span>
        ) : null}

        <ul {...getMenuProps()} className={styles.menu}>
          {isOpen &&
            options.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{
                    backgroundColor:
                      highlightedIndex === index ? '#D5D5D5' : 'white',
                  }}
                  {...getItemProps({item, index})}
                  className={styles.item}
                >
                  {item.label}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
