import {css} from 'styled-components';

import {colors} from '@stryd/react-ui';

export const errorBoxShadow = css`
  outline: none;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colors.themes.dark.error};
  }
`;
