import React from 'react';

import {useForm, Controller} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {BorderOnlyActionButton, dimensions} from '@stryd/react-ui';

import {Select} from 'src/components/Select';
import {AuthPageHeading} from 'src/components/checkout-auth-page/AuthPageHeading';

import {StepContainer} from './styles';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: ${dimensions.twicePadding};
  }
`;

const InputsContainer = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const SubmitButton = styled(BorderOnlyActionButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 9999px;
`;

const distanceUnitOptions = [
  {
    label: 'Kilometers',
    value: 'meters',
  },
  {
    label: 'Miles',
    value: 'feet',
  },
];
const temperatureUnitOptions = [
  {
    label: 'Fahrenheit',
    value: 'fahrenheit',
  },
  {
    label: 'Celsius',
    value: 'celcius',
  },
];
const goalRaceOptions = [
  {
    label: '5k',
    value: '5k',
  },
  {
    label: '10k',
    value: '10k',
  },
  {
    label: 'Half Marathon',
    value: 'half marathon',
  },
  {
    label: 'Marathon',
    value: 'marathon',
  },
  {
    label: 'Ultramarathon',
    value: 'ultramarathon',
  },
  {
    label: 'Half Ironman',
    value: 'half ironman',
  },
  {
    label: 'Ironman',
    value: 'ironman',
  },
];

export interface StepFourInputs {
  distanceUnit: string;
  temperatureUnit: string;
  goalRace: string;
}

export interface StepFourProps {
  onSubmit: (data: StepFourInputs) => void;
}

export const StepFour: React.FC<StepFourProps & StepFourInputs> = (props) => {
  const {distanceUnit, temperatureUnit, goalRace, onSubmit} = props;

  const intl = useIntl();

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm<StepFourInputs>({
    defaultValues: {
      distanceUnit,
      temperatureUnit,
      goalRace,
    },
  });

  const hasError = Object.keys(errors).length > 0;
  const disabled =
    hasError ||
    !watch('distanceUnit') ||
    !watch('goalRace') ||
    !watch('temperatureUnit');

  return (
    <StepContainer>
      <AuthPageHeading
        heading={intl.formatMessage({
          id: 'SignUpPageTemplate.steps.unitsAndGoals.header',
          defaultMessage: `Units and goals`,
        })}
        subHeading={intl.formatMessage({
          id: 'SignUpPageTemplate.steps.unitsAndGoals.message',
          defaultMessage: `We'll use this to report your information in the right units and to give relevant suggestions!`,
        })}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsContainer>
          <Controller
            name="distanceUnit"
            control={control}
            render={({field: {name, onChange, value}}) => (
              <Select
                placeholder={intl.formatMessage({
                  id: 'SignUpPageTemplate.steps.unitsAndGoals.distanceUnit.label',
                  defaultMessage: `Distance Unit`,
                })}
                name={name}
                onChange={onChange}
                value={value}
                options={distanceUnitOptions}
              />
            )}
          />
          <Controller
            name="temperatureUnit"
            control={control}
            render={({field: {name, onChange, value}}) => (
              <Select
                placeholder={intl.formatMessage({
                  id: 'SignUpPageTemplate.steps.unitsAndGoals.temperature.label',
                  defaultMessage: `Temperature Unit`,
                })}
                name={name}
                onChange={onChange}
                value={value}
                options={temperatureUnitOptions}
              />
            )}
          />
          <Controller
            name="goalRace"
            control={control}
            render={({field: {name, onChange, value}}) => (
              <Select
                placeholder={intl.formatMessage({
                  id: 'SignUpPageTemplate.steps.unitsAndGoals.goalRace.label',
                  defaultMessage: `Goal Race`,
                })}
                name={name}
                onChange={onChange}
                value={value}
                options={goalRaceOptions}
              />
            )}
          />
        </InputsContainer>

        <SubmitButton disabled={disabled} type="submit">
          <FormattedMessage id="actions.next" defaultMessage="Next" />
        </SubmitButton>
      </Form>
    </StepContainer>
  );
};
