import {useState, FC} from 'react';

import {useRouter} from 'next/router';
import {useIntl, FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {create} from '@stryd/api-client';
import {BorderOnlyActionButton, dimensions, Spinner} from '@stryd/react-ui';
import {isValidEmail} from '@stryd/util-lib';

import {TextInput} from 'src/components/TextInput';
import {AuthPageHeading} from 'src/components/checkout-auth-page/AuthPageHeading';
import {
  PageContainer,
  PageContentContainer,
} from 'src/components/checkout-auth-page/layout';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: ${dimensions.twicePadding};
  }
`;

const InputsContainer = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const SubmitButton = styled(BorderOnlyActionButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;

  > * + * {
    margin-left: ${dimensions.defaultPadding};
  }
`;

const strydApi = create({baseURL: process.env.NEXT_PUBLIC_STRYD_API_BASEURL});

export const ResetPasswordSend: FC = () => {
  const router = useRouter();
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setEmailError('');
    setSubmitting(true);

    if (!isValidEmail(email)) {
      setEmailError('Email is not valid');
      setSubmitting(false);
      return;
    }

    const res = await strydApi.user.sendPasswordResetLink(
      encodeURIComponent(email)
    );

    if (res.ok) {
      alert(
        intl.formatMessage({
          id: 'passwordReset.link.success',
        })
      );
      router.push('/signin');
    } else if (res.status === 403) {
      alert(
        intl.formatMessage({
          id: 'passwordReset.redirect',
        })
      );
      router.push('/signin');
    } else {
      alert(
        intl.formatMessage({
          id: 'passwordReset.error.account',
        })
      );
    }

    setSubmitting(false);
  };

  const formDisabled = !email || !!emailError;

  return (
    <PageContainer>
      <PageContentContainer>
        <AuthPageHeading
          heading={
            <FormattedMessage
              id="passwordReset.title"
              defaultMessage="Reset your password"
            />
          }
          subHeading={null}
        />

        <Form onSubmit={handleSubmit}>
          <InputsContainer>
            <TextInput
              label={intl.formatMessage({
                id: 'passwordReset.emailPlaceholder',
                defaultMessage: 'Input your account email',
              })}
              value={email}
              onChange={handleEmailChange}
              errorMessage={emailError}
              spellCheck={false}
              aria-required={true}
            />
          </InputsContainer>

          {/* TODO add forgot password, conditionally allow via prop */}

          <SubmitButton disabled={formDisabled} type="submit">
            <span>
              <FormattedMessage
                id="passwordReset.send.link"
                defaultMessage="Send Reset Link"
              />
            </span>
            {submitting && <Spinner size={'1rem'} />}
          </SubmitButton>
        </Form>
      </PageContentContainer>
    </PageContainer>
  );
};
