import React from 'react';

import {useForm, Controller} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {BorderOnlyActionButton, dimensions} from '@stryd/react-ui';

import {TextInput} from 'src/components/TextInput';
import {AuthPageHeading} from 'src/components/checkout-auth-page/AuthPageHeading';

import {StepContainer} from './styles';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: ${dimensions.twicePadding};
  }
`;

const InputsContainer = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const SubmitButton = styled(BorderOnlyActionButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 9999px;
`;

export interface StepTwoInputs {
  firstname: string;
  lastname: string;
}

export interface StepTwoProps {
  onSubmit: (data: StepTwoInputs) => void;
}

export const StepTwo: React.FC<StepTwoProps & StepTwoInputs> = (props) => {
  const {firstname, lastname, onSubmit} = props;

  const intl = useIntl();

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm<StepTwoInputs>({
    defaultValues: {
      firstname,
      lastname,
    },
    resolver: async (values) => {
      if (!values.firstname) {
        return {
          values,
          errors: {
            firstname: {
              type: 'required',
              message: intl.formatMessage({
                id: 'errors.required',
                defaultMessage: 'This is required.',
              }),
            },
          },
        };
      }
      if (!values.lastname) {
        return {
          values,
          errors: {
            lastname: {
              type: 'required',
              message: intl.formatMessage({
                id: 'errors.required',
                defaultMessage: 'This is required.',
              }),
            },
          },
        };
      }
      return {values, errors: {}};
    },
  });

  const disabled = !watch('firstname') || !watch('lastname');

  return (
    <StepContainer>
      <AuthPageHeading
        heading={intl.formatMessage({
          id: 'SignUpPageTemplate.steps.name.header',
          defaultMessage: `Who's account is this?`,
        })}
        subHeading={intl.formatMessage({
          id: 'SignUpPageTemplate.steps.name.message',
          defaultMessage:
            'We will keep this info private, and use it only to work with you and give you a quality experience.',
        })}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsContainer>
          <Controller
            name="firstname"
            control={control}
            render={({field: {name, onBlur, onChange, value}}) => (
              <TextInput
                label={intl.formatMessage({
                  id: 'SignUpPageTemplate.steps.name.firstName.label',
                  defaultMessage: 'First Name',
                })}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                errorMessage={errors?.firstname?.message}
                spellCheck={false}
                aria-required={true}
                autoComplete="firstname"
              />
            )}
          />
          <Controller
            name="lastname"
            control={control}
            render={({field: {name, onBlur, onChange, value}}) => (
              <TextInput
                label={intl.formatMessage({
                  id: 'SignUpPageTemplate.steps.name.lastName.label',
                  defaultMessage: 'Last Name',
                })}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                errorMessage={errors?.lastname?.message}
                spellCheck={false}
                aria-required={true}
                autoComplete="lastname"
              />
            )}
          />
        </InputsContainer>
        <SubmitButton type="submit" disabled={disabled}>
          <FormattedMessage id="actions.next" defaultMessage="Next" />
        </SubmitButton>
      </Form>
    </StepContainer>
  );
};
