import {useState, FC} from 'react';

import {useRouter} from 'next/router';
import {useIntl, FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {create} from '@stryd/api-client';
import {BorderOnlyActionButton, dimensions, Spinner} from '@stryd/react-ui';

import {TextInput} from 'src/components/TextInput';
import {AuthPageHeading} from 'src/components/checkout-auth-page/AuthPageHeading';
import {
  PageContainer,
  PageContentContainer,
} from 'src/components/checkout-auth-page/layout';
import {usePageRegionData} from 'src/contexts';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: ${dimensions.twicePadding};
  }
`;

const InputsContainer = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const SubmitButton = styled(BorderOnlyActionButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;

  > * + * {
    margin-left: ${dimensions.defaultPadding};
  }
`;

const strydApi = create({baseURL: process.env.NEXT_PUBLIC_STRYD_API_BASEURL});

export const ResetPasswordSession: FC<{sessionId: string}> = ({sessionId}) => {
  const {locale, region} = usePageRegionData();
  const router = useRouter();
  const intl = useIntl();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('');
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setPasswordError('');
    setSubmitting(true);

    const res = await strydApi.user.resetPassword({
      password: password.trim(),
      sessionId,
    });

    if (res.ok) {
      alert(
        intl.formatMessage({
          id: 'passwordReset.success.message',
        })
      );
      router.push(`/${region}/${locale}/signin`);
    } else {
      intl.formatMessage({
        id: 'passwordReset.failed.message',
      });
    }

    setSubmitting(false);
  };

  const formDisabled = !password || !!passwordError;

  return (
    <PageContainer>
      <PageContentContainer>
        <AuthPageHeading
          heading={
            <FormattedMessage
              id="passwordReset.title"
              defaultMessage="Reset your password"
            />
          }
          subHeading={null}
        />

        <Form onSubmit={handleSubmit}>
          <InputsContainer>
            <TextInput
              label={intl.formatMessage({
                id: 'passwordReset.passwordInputLabel',
                defaultMessage: 'Input new password',
              })}
              value={password}
              onChange={handlePasswordChange}
              errorMessage={passwordError}
              spellCheck={false}
              aria-required={true}
            />
          </InputsContainer>

          {/* TODO add forgot password, conditionally allow via prop */}

          <SubmitButton disabled={formDisabled} type="submit">
            <span>
              <FormattedMessage
                id="passwordReset.action"
                defaultMessage="Reset Password"
              />
            </span>
            {submitting && <Spinner size={'1rem'} />}
          </SubmitButton>
        </Form>
      </PageContentContainer>
    </PageContainer>
  );
};
