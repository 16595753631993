import {css} from 'styled-components';

import {colors as customColors} from './colors';

export const focusBoxShadow = css`
  outline: none;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${customColors.focusHighlight};
  }
`;
