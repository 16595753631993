import React from 'react';

import {useForm, Controller} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {BorderOnlyActionButton, dimensions} from '@stryd/react-ui';

import {StaticTypeahead} from 'src/components/Typeahead';
import {AuthPageHeading} from 'src/components/checkout-auth-page/AuthPageHeading';

import {StepContainer} from './styles';
import {timezoneOptions} from './timezone';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: ${dimensions.twicePadding};
  }
`;

const InputsContainer = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const SubmitButton = styled(BorderOnlyActionButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 9999px;
`;

export interface StepThreeInputs {
  timezone: string;
}

export interface StepThreeProps {
  onSubmit: (data: StepThreeInputs) => void;
}

export const StepThree: React.FC<StepThreeProps & StepThreeInputs> = (
  props
) => {
  const {timezone, onSubmit} = props;

  const intl = useIntl();

  const {handleSubmit, control, watch} = useForm<StepThreeInputs>({
    defaultValues: {
      timezone,
    },
  });

  const disabled = !watch('timezone');

  return (
    <StepContainer>
      <AuthPageHeading
        heading={intl.formatMessage({
          id: 'SignUpPageTemplate.steps.timezone.header',
          defaultMessage: `What is your timezone?`,
        })}
        subHeading={intl.formatMessage({
          id: 'SignUpPageTemplate.steps.timezone.message',
          defaultMessage: `So we can associate your running with the right day and time.`,
        })}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsContainer>
          <Controller
            name="timezone"
            control={control}
            render={({field: {name, onChange, value}}) => (
              <StaticTypeahead
                placeholder={intl.formatMessage({
                  id: 'SignUpPageTemplate.steps.timezone.firstName.label',
                  defaultMessage: `Time Zone`,
                })}
                name={name}
                onChange={onChange}
                value={value}
                options={timezoneOptions}
              />
            )}
          />
        </InputsContainer>
        <SubmitButton type="submit" disabled={disabled}>
          <FormattedMessage id="actions.next" defaultMessage="Next" />
        </SubmitButton>
      </Form>
    </StepContainer>
  );
};
