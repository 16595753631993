import styled from 'styled-components';

import {dimensions, colors} from '@stryd/react-ui';

export const ErrorContainer = styled.div`
  width: 100%;
  padding: ${dimensions.halfPadding} ${dimensions.defaultPadding};
  margin: 0;

  font-size: 0.8rem;
  border-radius: 4px;
  background-color: ${colors.themes.dark.errorAlertBackgroundColor};
  border: 1px solid ${colors.themes.dark.errorAlertBorderColor};
`;
