import {useState} from 'react';

import styled from 'styled-components';

import {dimensions, colors} from '@stryd/react-ui';

import {colors as customColors} from 'src/styles/colors';
import {errorBoxShadow} from 'src/styles/errorBoxShadow';
import {focusBoxShadow} from 'src/styles/focusBoxShadow';

import {ErrorContainer} from './ErrorContainer';

const labelPlaceholderFontSize = '1';
const labelSmallFontSize = '0.75';
const labelMarginBottom = '5px';
const visibleInputVerticalPad = '0.5rem';
const derivedTopInputPadding = `calc(${visibleInputVerticalPad} + ${labelSmallFontSize}rem + ${labelMarginBottom})`;
const inputPadding = `${derivedTopInputPadding} ${dimensions.defaultPadding} ${visibleInputVerticalPad}`;
const iconSize = '1.1rem';

interface PlaceholderLabel {
  isPlaceholder: boolean;
}

const getLabelTransform = ({isPlaceholder}: PlaceholderLabel) => {
  const scale = isPlaceholder ? labelPlaceholderFontSize : labelSmallFontSize;
  const topPad = isPlaceholder
    ? `calc(50% - (${scale}rem / 2))`
    : visibleInputVerticalPad;
  const leftPad = isPlaceholder ? 0 : '-9px';

  return `scale(${scale}) translateX(${leftPad}) translateY(${topPad})`;
};

const FieldContainer = styled.div`
  width: 100%;
  position: relative;

  &:focus-within {
    label {
      transform: ${() => getLabelTransform({isPlaceholder: false})};
    }
  }
`;

const InputContainer = styled.div`
  width: 100%;
`;

const Input = styled.input<{hasError?: boolean; hasIcon?: boolean}>`
  width: 100%;
  padding: ${inputPadding};
  padding-right: ${({hasIcon}) =>
    hasIcon
      ? `calc(2 * ${dimensions.defaultPadding} + ${iconSize})`
      : undefined};
  border-radius: ${({hasError}) => (hasError ? '4px 4px 0 0' : '4px')};
  border: ${({hasError}) =>
    hasError
      ? `1px solid ${colors.themes.dark.error}`
      : '1px solid transparent'};
  line-height: 1.2;
  color: ${customColors.inputPlaceholder};

  &:focus {
    ${({hasError}) => (hasError ? errorBoxShadow : focusBoxShadow)};
  }
`;

const IconContainer = styled.div`
  width: ${iconSize};
  height: ${iconSize};

  position: absolute;
  right: ${dimensions.defaultPadding};
  top: calc(50% - ${iconSize} / 2);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputErrorContainer = styled(ErrorContainer)`
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const Label = styled.label<{isPlaceholder: boolean}>`
  position: absolute;
  top: 0;
  left: 18px;
  width: 100%;
  height: 100%;
  font-size: 17px;
  pointer-events: none;
  transition: transform 0.2s ease;
  transform: ${getLabelTransform};
  transform-origin: 0 0;
  color: ${customColors.inputPlaceholder};
`;

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelStyle?: React.CSSProperties;
  errorMessage?: React.ReactNode;
  icon?: React.ReactNode;
}

export const TextInput: React.FC<TextInputProps> = (props) => {
  const {label, labelStyle, errorMessage, icon, ...inputProps} = props;
  const [value, setValue] = useState<string>('');

  const getValue = () => {
    return inputProps.value ? inputProps.value : value;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputProps.onChange) {
      inputProps.onChange(e);
    } else {
      setValue(e.target.value);
    }
  };

  return (
    <InputContainer>
      <FieldContainer>
        <Label isPlaceholder={getValue() === ''} style={labelStyle}>
          {label}
        </Label>
        <Input
          {...inputProps}
          aria-label={label}
          value={getValue()}
          onChange={handleChange}
          hasError={!!errorMessage}
          hasIcon={!!icon}
        />
        {icon && <IconContainer>{icon}</IconContainer>}
      </FieldContainer>
      {errorMessage && (
        <InputErrorContainer role="alert">{errorMessage}</InputErrorContainer>
      )}
    </InputContainer>
  );
};
