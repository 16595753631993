import {useState} from 'react';

import Image from 'next/image';
import {useIntl, defineMessage} from 'react-intl';
import styled from 'styled-components';

import {focusBoxShadow} from 'src/styles/focusBoxShadow';
import {withBasePath} from 'src/utils/router/withBasePath';

import {TextInput, TextInputProps} from './TextInput';

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const IconButton = styled.button`
  position: relative;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 3px;
  outline: none;
  background-color: transparent;
  padding: 0;

  ${focusBoxShadow};
`;

const showIconSrc = withBasePath('/icons/show.svg');
const showAltMessageDescriptor = defineMessage({
  id: 'actions.showPassword',
  defaultMessage: 'Show Password',
});
const hideIconSrc = withBasePath('/icons/hide.svg');
const hideAltMessageDescriptor = defineMessage({
  id: 'actions.hidePassword',
  defaultMessage: 'Hide Password',
});

export type PasswordInputProps = Omit<TextInputProps, 'type'>;

export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const intl = useIntl();

  const altMessageDescriptor = showPassword
    ? hideAltMessageDescriptor
    : showAltMessageDescriptor;
  const alt = intl.formatMessage(altMessageDescriptor);

  return (
    <InputContainer>
      <TextInput
        spellCheck={false}
        {...props}
        type={showPassword ? 'text' : 'password'}
        icon={
          <IconButton
            aria-label={alt}
            type="button"
            onClick={() => setShowPassword((curr) => !curr)}
          >
            <Image
              src={showPassword ? hideIconSrc : showIconSrc}
              alt={alt}
              layout="fill"
              objectFit="contain"
            />
          </IconButton>
        }
      />
    </InputContainer>
  );
};
