import React from 'react';

import Image from 'next/image';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {ActionButton, dimensions} from '@stryd/react-ui';

import {AuthPageHeading} from 'src/components/checkout-auth-page/AuthPageHeading';
import {redirectToPowerCenter} from 'src/features/auth/helpers';

import {StepContainer} from './styles';

const GoButton = styled(ActionButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 225px;
  margin: 0 auto;

  &:last-child {
    margin-top: ${dimensions.halfPadding};
  }
`;

const SuccessImage = styled.div`
  display: block;
  margin-bottom: 100px;
  text-align: center;
`;

export const StepFinished: React.FC = () => {
  const intl = useIntl();

  return (
    <StepContainer>
      <AuthPageHeading
        heading={intl.formatMessage({
          id: 'SignUpPageTemplate.steps.finished.header',
          defaultMessage: `You're ready to run!`,
        })}
        subHeading={intl.formatMessage({
          id: 'SignUpPageTemplate.steps.finished.message',
          defaultMessage: `You're Stryd account is created. Continue to go to PowerCenter, or log into the mobile app to get started!`,
        })}
      />
      <SuccessImage>
        <Image
          src="/images/account-creation-success.png"
          alt="Account Creation Success"
          width="238px"
          height="260px"
        />
      </SuccessImage>
      <GoButton onClick={() => redirectToPowerCenter()} type="button">
        {intl.formatMessage({
          id: 'SignUpPageTemplate.steps.finished.action',
          defaultMessage: `Go to PowerCenter`,
        })}
      </GoButton>
    </StepContainer>
  );
};
