import {TextInput, TextInputProps} from './TextInput';

export interface NumberInputProps extends TextInputProps {
  integerOnly?: boolean;
}

export const NumberInput: React.FC<NumberInputProps> = (props) => {
  const {integerOnly, ...rest} = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (integerOnly && e.target.value.includes('.')) {
      e.preventDefault();
      return;
    }
    rest.onChange?.(e);
  };

  return <TextInput {...rest} onChange={handleChange} />;
};
