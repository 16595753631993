/* eslint-disable jsx-a11y/label-has-associated-control  */
import {FC} from 'react';

import cn from 'classnames';
import {useCombobox} from 'downshift';

import styles from './Typeahead.module.css';

export type TypeaheadValue = string;

export interface TypeaheadOption {
  label: string;
  value: string;
}

export interface TypeaheadProps {
  label?: string;
  placeholder?: string;
  name?: string;
  inputValue: string;
  value?: TypeaheadOption | null;
  options: TypeaheadOption[];
  onChange: (item: TypeaheadOption | null) => void;
  onInputValueChange: (value: string) => void;
}

export const Typeahead: FC<TypeaheadProps> = (props) => {
  const {
    label,
    placeholder,
    inputValue,
    value,
    options,
    onChange,
    onInputValueChange,
  } = props;
  const {
    selectedItem,
    isOpen,
    openMenu,
    getLabelProps,
    getInputProps,
    getComboboxProps,
    // getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    inputValue,
    items: options,
    itemToString: (item) => (item ? item.label : ''),
    defaultSelectedItem: value,
    onSelectedItemChange: ({selectedItem}) => {
      onChange(selectedItem ?? null);
    },
    onInputValueChange: ({inputValue}) => {
      onInputValueChange(inputValue ?? '');
    },
  });

  return (
    <div {...getComboboxProps()} className={styles.wrapper}>
      {label ? <label {...getLabelProps()}>{label}</label> : null}

      <div>
        <input
          {...getInputProps({
            onClick: () => {
              openMenu();
            },
          })}
          className={styles.input}
        />
        {/* <button
          type="button"
          {...getToggleButtonProps()}
          className={cn([styles.toggle, isOpen && styles.toggleOpen])}
        >
          {selectedItem?.label}
        </button> */}
        {placeholder ? (
          <span
            className={cn([
              styles.placeholder,
              (selectedItem || inputValue) && styles.placeholderAway,
            ])}
          >
            {placeholder}
          </span>
        ) : null}

        <ul {...getMenuProps()} className={styles.menu}>
          {isOpen &&
            options.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{
                    backgroundColor:
                      highlightedIndex === index ? '#D5D5D5' : 'white',
                  }}
                  {...getItemProps({item, index})}
                  className={styles.item}
                >
                  {item.label}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
