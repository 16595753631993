import {FC, useMemo, useState} from 'react';

import {Typeahead, TypeaheadValue, TypeaheadOption} from './Typeahead';

export interface StaticTypeahead {
  label?: string;
  placeholder?: string;
  name?: string;
  value?: TypeaheadValue | null;
  options: TypeaheadOption[];
  onChange: (value?: string) => void;
  onInputValueChange?: (value: string) => void;
}

const filterOptionsByLabel = (
  options: TypeaheadOption[],
  inputValue: string
) => {
  return options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

export const StaticTypeahead: FC<StaticTypeahead> = (props) => {
  const {value, options, onChange} = props;

  const [inputValue, setInputValue] = useState(() => {
    if (value) {
      return options.find((option) => option.value === value)?.label ?? '';
    }
    return '';
  });

  const onInputValueChange = (value: string) => {
    setInputValue(value);
  };

  const optionsProp = useMemo(
    () => filterOptionsByLabel(options, inputValue),
    [inputValue, options]
  );

  const valueProp = useMemo(
    () => optionsProp.find((option) => option.value === value),
    [optionsProp, value]
  );

  return (
    <Typeahead
      {...props}
      value={valueProp}
      options={optionsProp}
      inputValue={inputValue}
      onInputValueChange={onInputValueChange}
      onChange={(item) => {
        if (item) {
          onChange(item.value);
        } else {
          onChange(undefined);
        }
      }}
    />
  );
};
